.footer {
  &__top {
    background-color: #a7d379;
    padding: 50px 0;
    color: #fff;
    @media only screen and (max-width: 767px) {
      padding: 30px 0;
    }

    .footer_linksTitle {
      font-size: 16pt;
      line-height: normal;
      font-weight: bold;
      color: #fff;
      @media only screen and (max-width: 767px)  {
        font-size: 14pt;
      }
    }

    .footer_logo {
      p {
        padding-top: 18px;
        font-size: 11pt;
        line-height: normal;
      }
    }
    .footer-contact {
      .details_card {
        align-items: center;
        .icons {
          img {
            height: 36px;
          }
        }
      }
    }

    .footer_unitLinks {
      .footer-menu {
        padding: 0;

        li {
          list-style: none;
          margin-bottom: 16px;
          @media only screen and (max-width: 767px) {
            margin-bottom: 12px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          a {
            text-decoration: none;
            font-size: 14pt;
            line-height: normal;
            color: #fff;
            font-weight: 500;
            &:hover {
              color: #edb405;
            }
            @media only screen and (max-width: 767px) {
              font-size: 13pt;
            }
          }
        }
      }
    }

    .footer-socailLinks {
      .details_card {
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        .icons {
          background-color: #fff;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
         

          svg {
            color: #a7d379;
            height: 24px;
            width: 24px;
          }
        }
      }
    }

    .details_card {
      display: flex;
      gap: 15px;
      margin-top: 20px;
      @media only screen and (max-width: 767px)  {
        margin-top: 10px;
      }

      .icons {
        svg,img {
          color: #fff;
          height: 30px;
        }
      }

      .containt {
        span {
          font-size: 11pt;
          line-height: normal;
          color: #fff;
          margin-bottom: 5px;
          display: block;
        }

        a {
          font-size: 13pt;
          line-height: normal;
          color: #fff;
          text-decoration: none;
          margin-right: 20px;

          &:last-child {
            margin-right: 0px;
          }
        }

        p {
          font-size: 13pt;
          line-height: normal;
          color: #fff;
        }

      }
    }
  }

  &__bottom {
    padding: 14px;

    p {
      font-size: 13pt;
      text-align: center;
      color: #4D4D4D;

    }
  }
}