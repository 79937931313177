.contactUs {
    padding: 115px 0;
    background-color: #fff;
    @media only screen and (max-width: 991px) {
        padding: 50px 0;
    }
    @media only screen and (max-width: 575px) {
        padding: 30px 0;
    }

    .form_block {
        padding: 40px;
        background-color: #A7D379;
        border-radius: 20px;
        @media only screen and (max-width: 991px) {
            padding: 30px 20px;
        }

        .input_box {
            display: flex;
            gap: 20px;
            @media only screen and (max-width: 575px) {
                flex-direction: column;
                gap: 10px;
            }
        }

        &__form-control {
            width: 100%;

            margin-bottom: 20px;

            .label {
                font-size: 15pt;
                line-height: 20px;
                color: #FFFFFF;
                font-weight: normal;
                display: flex;
                margin-bottom: 8px;
                text-transform: capitalize;
                @media only screen and (max-width: 991px) {
                    font-size: 13pt;
                 }
            }

            input,
            textarea {
                border: none;
                border-radius: 20pt;
                padding: 12px 20px;
                background-color: #fff;
                width: 100%;
                font-size: 18px;
                // @media only screen and (max-width: 991px) {
                //    font-size: 18pt;
                // }

                &:focus-visible {
                    outline: none;
                }
            }
        }

        .contact-btn {
            border-radius: 10px;
            background-color: #598E38;
            color: #fff;
            font-size: 18px;
            line-height: normal;
            padding: 15px 25px;
            border: none;
            margin-left: auto;
            display: block;
            cursor: pointer;
            @media only screen and (max-width: 767px)  {
                font-size: 15px;
                padding: 10px 15px;
              }
        }
    }

    .contact_details {
        .subtitle {
            font-size: 22pt;
            line-height: normal;
            color: #666666;
            font-weight: 400;
            text-transform: capitalize;
            @media only screen and (max-width: 991px) {
                font-size: 18pt;
             }
        }

        .title {
            font-size: 35pt;
            line-height: normal;
            color: #A7D379;
            font-weight: bold;
            font-weight: 400;
            margin: 20px 0 25px;
            @media only screen and (max-width: 991px) {
                font-size: 30pt;
                margin: 14px 0 18px;
             }
             @media only screen and (max-width: 575px) {
                font-size: 26pt;
                margin: 14px 0 18px;
             }
        }

        p {
            font-size: 18pt;
            line-height: normal;
            color: #666666;
            @media only screen and (max-width: 991px) {
                font-size: 16pt;
             }
        }

        .details_card {
            display: flex;
                gap: 35px;
                // align-items: center;
                margin-top: 30px;
                @media only screen and (max-width: 767px) {
                    gap: 20px;  
                }
            .icons {
                height: 55px;
                width: 55px;
                border-radius: 50%;
                background-color: #E6E6E6;
                display: flex;
                align-items: center;
                justify-content: center;
                // @media only screen and (max-width: 991px) {
                //     height: 45px;
                //     width: 45px;
                //  }
                img {
                    width: 100%;
                }
                svg {
                    color: #A7D379;
                    height: 30px;
                    width: 30px;
                }
            }
            .containt {
                width: calc(100% - 70px);
                span {
                    font-size: 11pt;
                    line-height: normal;
                    color: #666666;
                    margin-bottom: 5px;
                    display: block;
                }
                a {
                    font-size: 13pt;
                    line-height: normal;
                    color: #666666;
                    text-decoration: none;
                    margin-right: 20px;
                    &:last-child {
                        margin-right: 0px;
                    }
                }
                p {
                    font-size: 13pt;
                    line-height: normal;
                    color: #666666;
                }

            }
        }
    }
}