// Common Style
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.text-center {
  text-align: center;
}
* {
  box-sizing: border-box;
}
p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
}
.container {
  max-width: 1410px;
  margin: 0 auto;
  // width: calc(100% - 30px);
  padding: 0 15px;
  position: relative;
}
.lightBtn {
  border: 1px solid #a7cb77;
  color: #a7cb77;
  background: #fff;
  padding: 8px 15px;
  border-radius: 30px;
  font-size: 14px;
  line-height: normal;
  cursor: pointer;
  box-shadow: 0 0 10px #0003;
  transition: 0.3s;
  &:hover {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
  }
}
.section-title {
  font-size: 32pt;
  line-height: normal;
  margin-bottom: 30px;
  color: #000;
  font-weight: bold;
  @media only screen and (max-width: 991px) {
    font-size: 28pt;
    line-height: normal;
  }
  @media only screen and (max-width: 575px) {
    font-size: 22pt;
  }
  span {
    color: #a6c976;
  }
}
.ScrollBtn {
  border: none;
  background-color: #487833;
  border-radius: 100%;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  right: 5%;
  img {
    height: 22px;
  }
}
.FruitsBoxModel::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}
 
.FruitsBoxModel::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}
 
.FruitsBoxModel::-webkit-scrollbar-thumb {
  background: #E0A00E;
  border-radius: 20px; 
}
