.mapsection {
    position: relative;

    &__img {
        width: 100%;
        height: auto;

        @media only screen and (max-width: 768px) {
            min-height: 154px;
            object-fit: contain;
        }
    }

    &__content {
        position: absolute;
        max-width: 90%;
        width: calc(100% - 40px);
        top: 80px;
        left: 50%;
        text-align: center;
        color: #fff;
        transform: translate(-50%, 0);
        @media only screen and (max-width: 1199px) {
            top: 70px;
        }
        @media only screen and (max-width: 768px) {
            top: 30px;
        }
        @media only screen and (max-width: 575px) {
            top: 10px;
        }

        p {
            margin-bottom: 20px;
            font-size: 18px;
            color: #fff;
            @media only screen and (max-width: 768px) {
                margin-bottom: 6px;
            }
            @media only screen and (max-width: 575px) {
                margin-bottom: 0px;
            }
        }

        h2 {
            font-size: 32pt;
            line-height: normal;

            @media only screen and (max-width: 991px) {
                font-size: 26px;
            }

            @media only screen and (max-width: 575px) {
                font-size: 20px;
            }

            span {
                color: #EDB405;
            }
        }

        button {
            padding: 15px 25px;
            border: 1px solid #fff;
            background-color: transparent;
            color: #fff;
            border-radius: 10px;
            font-size: 18px;
            line-height: normal;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
                background-color: #a6c976;
                border-color: #a6c976;
            }
        }
    }
}