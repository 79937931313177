.Heroslide {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }
  img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
    @media only screen and (max-width: 991px) {
      height: 50vh;
    }
  }
  &__content {
    position: absolute;
    max-width: 630px;
    width: calc(100% - 40px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    p {
      margin-bottom: 20px;
      font-size: 18px;
      color: #fff;
    }
    h2 {
      font-size: 56px;
      margin-bottom: 45px;
      line-height: normal;
      @media only screen and (max-width: 991px) {
        font-size: 46px;
      }
      @media only screen and (max-width: 575px) {
        font-size: 36px;
        margin-bottom: 25px;
      }
      span {
        color: #EDB405;
      }
    }
    button {
      padding: 15px 25px;
      border: 1px solid #fff;
      background-color: transparent;
      color: #fff;
      border-radius: 10px;
      font-size: 18px;
      line-height: normal;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: #a6c976;
        border-color: #a6c976;
      }
      @media only screen and (max-width: 575px) {
      padding: 8px 15px;
      font-size: 14px;
      }
    }
  }
}
.HeroSlider {
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    bottom: 60px;
    @media only screen and (max-width: 575px) {
      bottom: 30px;
    }
    li {
      button {
        padding: 0;
        &::before {
          color: #fff !important;
          font-size: 16px;
        }
      }
    }
  }
}
