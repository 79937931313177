.whoweare {
  padding: 100px 0;
  @media only screen and (max-width: 991px) {
    padding: 50px 0;
  }
  @media only screen and (max-width: 575px) {
    padding: 30px 0;
  }
}
.contentwrapper {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0 50px 0 20px;
  @media only screen and (max-width: 768px) {
    gap: 20px;
    flex-direction: column;
    padding: 0;
  }
  &__image {
    width: 40%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    img {
      width: 100%;
    }
  }
  &__Content {
    width: 60%;
    @media only screen and (max-width: 768px) {
        width: 100%;
    }
    p {
      line-height: 32px;
    }
  }
  .section-title {
    font-size: 27pt;
  }
}
.section-label {
  font-size: 20px;
  margin-bottom: 20px;
  color: #666666;
}

