.policy-section {
    .policy {
        padding: 100px 0 80px;
        background-color: #A7D379;

        @media only screen and (max-width: 991px) {
            padding: 50px 0;
        }

        p {
            font-size: 21pt;
            line-height: normal;
            font-weight: 400;
            color: #fff;
            text-align: justify;

            @media only screen and (max-width: 991px) {
                font-size: 18pt;
            }

            @media only screen and (max-width: 575px) {
                font-size: 14pt;
            }
        }

        .policy_titleBlock {
            h2 {
                font-size: 32pt;
                line-height: normal;
                font-weight: bold;
                color: #fff;
                text-align: center;
                margin-bottom: 35px;

                @media only screen and (max-width: 991px) {
                    font-size: 24pt;
                }

                @media only screen and (max-width: 575px) {
                    font-size: 20pt;
                }
            }
        }

        .policy_Content {
            padding-top: 50px;
            gap: 40px;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 767px) {
                gap: 30px;
            }

            .policy_Content_block {
                .subtitle {
                    font-size: 26pt;
                    line-height: normal;
                    color: #fff;
                    font-weight: bold;
                    background-color: #EDB405;
                    padding: 3px 20px;
                    border-radius: 30px;

                    @media only screen and (max-width: 991px) {
                        font-size: 20pt;
                    }

                    @media only screen and (max-width: 575px) {
                        font-size: 16pt;
                    }
                }

                p {
                    padding-top: 20px;

                    @media only screen and (max-width: 575px) {
                        padding-top: 15px;
                    }
                }
            }
        }
    }

    .date {
        padding: 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 575px) {
            flex-direction: column;
            gap: 15px;
        }

        p {
            font-size: 18pt;
            line-height: normal;
            color: #333;
            font-weight: 400;
            text-align: end;

            @media only screen and (max-width: 575px) {
                font-size: 14pt;
            }
        }
    }
}