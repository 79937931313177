.header {
 background-color: #fff;
 position: sticky;
 top: 0;
 width: 100%;
 left: 0;
 z-index: 99;

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  position: relative;
  
}
.menuList {
  display: flex;
  align-items: center;
  gap: 100px;
  @media only screen and (max-width: 1299px) {
    gap: 50px;
  }
  @media only screen and (max-width: 991px) {
    position: absolute;
    display: block;
    width: 100%;
    left: 0;
    top: 80px;
    z-index: 1;
    background-color: #fff;
    padding: 20px 0;
    transition: 0.3s;
    transform: translateY(-250px);
    &.open {
      transform: unset;
      top: 124px;
    }
  }
  &__item {
    display: block;
    text-decoration: none;
    color: #333;
    font-size: 20px;
    transition: 0.3s;
    &:hover {
      color: #edb405;
    }
    @media only screen and (max-width: 991px) {
      padding: 4px 15px;
      font-size: 18px;
    }
  }
}
.MenuBtn {
  background-color: transparent;
  border: none;
  padding: 0;
  display: none;
  cursor: pointer;
  img {
    max-width: 25px;
  }
  @media only screen and (max-width: 991px) {
    display: block;
  }
}
}