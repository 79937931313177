.Ourspecialties {
  padding: 100px;
  @media only screen and (max-width: 991px) {
    padding: 50px;
  }
  @media only screen and (max-width: 575px) {
    padding: 30px 0;
  }
  p {
    font-size: 21pt;
    @media only screen and (max-width: 767px) {
      font-size: 14pt;
      line-height: normal;
    }
  }
}
.fruitsBox {
  display: grid;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 100px 0 90px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media only screen and (max-width: 575px)  {
    margin: 50px 0 30px;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }
  &__inner {
    text-align: center;
    width: fit-content;
    @media only screen and (max-width: 575px)  {
     width: auto;
    }
    .productBox {
      width: 104px;
      height: 104px;
      background-color: #a7cb77;
      border-radius: 20px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      padding: 20px 20px 30px;
      @media only screen and (max-width: 575px)  {
        // width: 300px;
        margin: 0 auto;
      }
      img {
        width: 100%;
        object-fit: contain;
        // filter: invert(1);
        @media only screen and (max-width: 767px)  {
          width: auto;
          height: 60px;
          margin: 0 auto;
          display: block;
        }
      }
    }
    p {
      font-size: 14pt;
      text-align: center;
      width: fit-content;
      margin: auto;
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 10px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      margin-top: -14px;
      color: #A6CA76;
    }
  }
}
