.FruitsSlider {
  background-color: #edb405;
  padding: 80px 0;
  position: relative;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../Asset/images/FruitsSliderShape.png);
  }

  p {
    font-size: 21pt;
    margin-bottom: 20px;
    color: #fff;
  }

  .section-title {
    color: #fff;

    span {
      color: #487833;
    }
  }
}

.FruitsSliderBox {
  padding: 80px 0;
  position: relative;

  @media only screen and (max-width: 991px) {
    padding: 40px 0;
  }

  @media only screen and (max-width: 767px) {
    padding: 10px 0;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-slider {
    position: unset;
  }

  .slick-dots {
    li {
      width: 14px;
      height: 14px;

      button {
        padding: 0;
        width: 14px;
        height: 14px;
        font-size: 0;
        background-color: #487833;
        border-radius: 100%;
        opacity: 0.5;

        &:before {
          content: unset;
        }
      }

      &.slick-active {
        button {
          opacity: 1;
        }
      }
    }
  }
}

.fruitsSlide {
  background-color: #fff;
  margin: 0 25px;
  border-radius: 20px;
  overflow: hidden;

  @media only screen and (max-width: 1199px) {
    margin: 0 15px;
  }

  // @media only screen and (max-width: 740px) {
  //   margin: 20px auto;
  //   max-width: 400px;
  // }

  &__img {
    @media only screen and (max-width: 740px) {
      text-align: center;
    }

    img {
      width: 100%;
      height: 211px;
      object-fit: contain;
      background-color: white;

      @media only screen and (max-width: 740px) {
        height: 310px;
        // object-fit: cover;
      }
    }
  }

  &__content {
    padding: 25px 35px;

    @media only screen and (max-width: 740px) {
      text-align: center;
    }
  }

  &__title {
    font-size: 25pt;
    color: #edb405;
    margin-bottom: 10px;
    min-height: 64px;

    @media only screen and (max-width: 991px) {
      font-size: 20pt;
    }

    span {
      display: block;
      font-size: 15pt;
    }
  }
}

.fruitscategory {
  display: flex;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 740px) {
    justify-content: center;
  }

  &__inner {
    width: 33px;
    height: 33px;
    border-radius: 6px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      // filter: invert(1);
    }
  }
}

.custom_modal {
  left: 15px !important;
  right: 15px !important;
  .MuiBox-root {
    border-radius: 20px;
    max-width: 527px;
    width: 100%;
    padding: 80px 32px;
    box-shadow: unset;
    @media only screen and (max-width: 991px) {
      padding: 50px 20px;
    }
    @media only screen and (max-width: 991px) {
      padding: 20px;
    }
  }

  &.CateoryCalendar {
    .MuiBox-root {
      .title {
        font-size: 40pt;
        line-height: normal;
        font-weight: bold;
        text-align: center;
        color: #edb405;
        @media only screen and (max-width: 991px) {
          font-size: 30pt;
        }
        @media only screen and (max-width: 575px) {
          font-size: 25pt;
        }
        ~ div {
          gap: 10px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          div {
            align-items: center;
            @media only screen and (max-width: 575px) {
              margin: auto !important;
              width: 60px !important;
              height: 60px !important;
              padding: 5px !important;
            }
            @media only screen and (max-width: 360px) {
            width: 50px !important;
            height: 50px !important;
            }
          }
        }
      }

      .MuiDateCalendar-root {
        width: 100%;
        height: auto;

        .MuiPickersCalendarHeader-root {
          width: 100%;
          justify-content: center;

          .MuiPickersCalendarHeader-labelContainer {
            margin-right: unset;
            justify-content: center;
          }

          .MuiPickersArrowSwitcher-root {
            display: none;
          }
        }

        .MuiYearCalendar-root {
          width: 100%;
        }

        .MuiMonthCalendar-root {
          width: 100%;

          .MuiPickersMonth-root {
            flex-basis: 25%;

            .MuiPickersMonth-monthButton {
              height: 70px;
              width: 70px;
              font-size: 12pt;
              color: #4d4d4d;
              line-height: normal;
              font-weight: bold;
              @media only screen and (max-width: 767px) {
                height: 50px;
                width: 50px;
              }

              &.Mui-selected {
                background-color: #edb405;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  &.CateorySecond {
    .MuiBox-root {
      .title {
        color: #a7d379;
      }
    }

    .MuiPickersMonth-monthButton {
      &.Mui-selected {
        background-color: #a7d379 !important;
        color: #fff !important;
      }
    }
  }

  &.CateoryThird {
    .MuiBox-root {
      .title {
        color: #593a18;
      }
    }

    .MuiPickersMonth-monthButton {
      &.Mui-selected {
        background-color: #593a18 !important;
        color: #fff !important;
      }
    }
  }
}

.FruitsBoxModel {
  max-height: 446px;
  overflow: auto;

  .FruitsBox {
    display: grid;
    grid-template-columns: 95px 1fr;
    gap: 18px;
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
      grid-template-columns: 63px 1fr;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__icon {
      // background-color: #e0a00e;
      // border-radius: 20px;
      // padding: 25px;
      // width: 95px;
      // height: 95px;
      // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      @media only screen and (max-width: 767px) {
        // padding: 16px;
        width: 60px;
        height: 60px;
      }
      img {
        width: 100%;
      }
    }

    &__content {
      h2 {
        font-size: 24pt;
        color: #edb405;
        font-weight: bold;
        @media only screen and (max-width: 991px) {
          font-size: 18pt;
        }
        @media only screen and (max-width: 575px) {
          font-size: 16pt;
        }
      }

      p {
        font-size: 14pt;
        font-weight: 500;
        color: #4d4d4d;
        line-height: normal;
        @media only screen and (max-width: 991px) {
          font-size: 15pt;
        }
        @media only screen and (max-width: 575px) {
          font-size: 13pt;
        }
      }
    }
  }
}
