.OurMission {
  display: flex;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }

  &__inner {
    width: 33.33%;
    padding: 75px;

    // height: 100%;
    @media only screen and (max-width: 1199px) {
      padding: 45px;
    }

    @media only screen and (max-width: 991px) {
      padding: 25px;
      // width: calc(100% - 50px);
      width: 100%;
    }

    p {
      font-size: 13pt;
      color: #fff;
      line-height: 32px;
      text-align: center;

      @media only screen and (max-width: 575px) {
        line-height: 20pt;
      }
    }
  }

  .title {
    font-size: 23pt;
    line-height: normal;
    margin-bottom: 20px;
    color: #fff;
    font-weight: bold;
    text-align: center;

    @media only screen and (max-width: 575px) {
      font-size: 14pt;
    }

  }

  .iconBox {
    background-color: #a7cb77;
    width: 66px;
    height: 66px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    padding: 12px;
    margin: auto;
    margin-bottom: 20px;

    img {
      width: 100%;
    }
  }
}